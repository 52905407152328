/**
 * Created by preference on 2023/12/27
 *  zmx
 */

import * as API from "@/api/index";
export default {
  // 列表
  entityConversionRepurchaseRateStatement_list: (params) => {
    return API.POST("api/entityConversionRepurchaseRateStatement/list", params);
  },
  // 导出
  entityConversionRepurchaseRateStatement_excel: (params) => {
    return API.exportExcel("api/entityConversionRepurchaseRateStatement/excel", params);
  },
  // 查询门店
  reportEntity_storeList: (params) => {
    return API.POST("api/reportEntity/storeList", params);
  },
};
