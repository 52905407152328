<template>
  <div class="ConversionRepurchaseRate content_body">
    <div class="nav_header" style="padding:0px">
      <el-form :inline="true" size="small" :model="searchForm" @submit.native.prevent>
        <el-form-item label="门店">
          <el-select v-model="searchForm.EntityID" clearable filterable placeholder="请选择职务" @change="onHandleSearch">
            <el-option v-for="item in entity_list" :label="item.EntityName" :value="item.ID" :key="item.ID"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="时间筛选">
          <el-date-picker v-model="searchForm.SearchDates" unlink-panels type="daterange" range-separator="至" value-format="yyyy-MM-dd" start-placeholder="开始日期" end-placeholder="结束日期" @change="onHandleSearch" :clearable="false"></el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" v-prevent-click @click="onHandleSearch">搜索</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" v-prevent-click @click="downloadExcel" :loading="downloadExcelLoading" v-if="isExport">导出</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table size="small" show-summary :data="tableData" v-loading="tableLoading" :summary-method="getListSummaries">
      <el-table-column prop="EntityName" label="门店名称"></el-table-column>
      <el-table-column prop="CustomerTotalNumber" label="顾客总人数"></el-table-column>
      <el-table-column prop="NewCreatedCustomerNumber" label="本期新注册人数"></el-table-column>
      <el-table-column prop="SignContractNewCustomerNumber" label="签约新会员人数"></el-table-column>
      <el-table-column prop="NotSignContractNewCustomerNumber" label="不签约新会员人数"></el-table-column>
      <el-table-column prop="YearsFirstSaleJY" label="本年度会员首次购买精油"></el-table-column>
      <el-table-column prop="DayFirstSaleJY" label="当日新会员连带精油"></el-table-column>
      <el-table-column prop="ConversionRate" label="转化率"></el-table-column>
      <el-table-column prop="SaleJYCustomerNumber" label="购买精油总人数"></el-table-column>
      <el-table-column prop="RepurchaseCustomerNumber" label="复购精油人数"></el-table-column>
      <el-table-column prop="RepurchaseRate" label="复购率"></el-table-column>
    </el-table>
    <div class="pad_15 text_right">
      <el-pagination background v-if="paginations.total > 0" @current-change="handlePageChange" :current-page.sync="paginations.page" :page-size="paginations.page_size" :layout="paginations.layout" :total="paginations.total"></el-pagination>
    </div>
  </div>
</template>

<script>
import API from "@/api/Report/Entity/conversionRepurchaseRate.js";
import permission from "@/components/js/permission.js";
var dayjs = require("dayjs");
export default {
  name: "ConversionRepurchaseRate",

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.isExport = permission.permission(to.meta.Permission, "Report-Entity-ConversionRepurchaseRate-Export");
    });
  },
  props: {},
  /** 监听数据变化   */
  watch: {},
  /**  引入的组件  */
  components: {},
  /**  Vue 实例的数据对象**/
  data() {
    return {
      downloadExcelLoading: false,
      isExport: false,
      tableLoading: false,
      //需要给分页组件传的信息
      paginations: {
        page: 1, // 当前位于哪页
        total: 1, // 总数
        page_size: 10, // 1页显示多少条
        layout: "total, prev, pager, next,jumper", // 翻页属性
      },
      searchForm: {
        EntityID: null, //门店编码
        SearchDates: [dayjs().format("YYYY-MM-DD"), dayjs().format("YYYY-MM-DD")],
        PageNum: 1, //页码
      },
      tableData: [],
      sumForm: {
        CustomerTotalNumber: 0, //顾客总人数
        NewCreatedCustomerNumber: 0, //本期新注册人数
        SignContractNewCustomerNumber: 0, //签约新会员人数
        NotSignContractNewCustomerNumber: 0, //不签约新会员人数
        YearsFirstSaleJY: 0, //本年度会员首次购买精油
        DayFirstSaleJY: 0, //当日新会员连带精油
        SaleJYCustomerNumber: 0, //购买精油总人数
        RepurchaseCustomerNumber: 0, //复购精油人数
      },
      entity_list: [],
    };
  },
  /**计算属性  */
  computed: {},
  /**  方法集合  */
  methods: {
    /**    */
    handlePageChange(page) {
      let that = this;
      that.paginations.page = page;
      that.entityConversionRepurchaseRateStatement_list();
    },
    /**    */
    onHandleSearch() {
      let that = this;
      that.paginations.page = 1;
      that.entityConversionRepurchaseRateStatement_list();
    },

    // 合计
    getListSummaries(param) {
      const { columns } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = <span class="font_weight_600">合计</span>;
          return;
        }
        switch (column.property) {
          case "CustomerTotalNumber":
            sums[index] = <span class="font_weight_600">{this.sumForm.CustomerTotalNumber}</span>;
            break;
          case "NewCreatedCustomerNumber":
            sums[index] = <span class="font_weight_600">{this.sumForm.NewCreatedCustomerNumber}</span>;
            break;
          case "SignContractNewCustomerNumber":
            sums[index] = <span class="font_weight_600">{this.sumForm.SignContractNewCustomerNumber}</span>;
            break;
          case "NotSignContractNewCustomerNumber":
            sums[index] = <span class="font_weight_600">{this.sumForm.NotSignContractNewCustomerNumber}</span>;
            break;
          case "YearsFirstSaleJY":
            sums[index] = <span class="font_weight_600">{this.sumForm.YearsFirstSaleJY}</span>;
            break;
          case "DayFirstSaleJY":
            sums[index] = <span class="font_weight_600">{this.sumForm.DayFirstSaleJY}</span>;
            break;
          case "SaleJYCustomerNumber":
            sums[index] = <span class="font_weight_600">{this.sumForm.SaleJYCustomerNumber}</span>;
            break;
          case "RepurchaseCustomerNumber":
            sums[index] = <span class="font_weight_600">{this.sumForm.RepurchaseCustomerNumber}</span>;
            break;
          default:
            sums[index] = <span class="font_weight_600"></span>;
        }
      });
      return sums;
    },
    /**    */
    entityConversionRepurchaseRateStatement_list() {
      let that = this;
      let params = {
        EntityID: that.searchForm.EntityID, //门店编码
        StartDate: that.searchForm.SearchDates[0], //开始时间
        EndDate: that.searchForm.SearchDates[1], //开始时间
        PageNum: that.paginations.page, //页码
      };
      that.tableLoading = true;
      API.entityConversionRepurchaseRateStatement_list(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.tableLoading = false;
            that.tableData = res.Data.detail.List;
            that.sumForm = res.Data.entityConversionRepurchaseRateSumForm;
            that.paginations.total = res.Data.detail.Total;
            that.paginations.page_size = res.Data.detail.PageSize;
          } else {
            that.tableLoading = false;
            that.$message.error(res.Message);
          }
        })
        .catch((fail) => {
          that.tableLoading = false;
          that.$message.error(fail);
        });
    },
    /**    */
    reportEntity_storeList() {
      let that = this;
      let params = {};
      API.reportEntity_storeList(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.entity_list = res.Data;
          } else {
            that.$message.error(res.Message);
          }
        })
        .catch((fail) => {
          that.$message.error(fail);
        });
    },

    /** 数据导出 */
    downloadExcel() {
      var that = this;
      let params = {
        EntityID: that.searchForm.EntityID, //门店编码
        StartDate: that.searchForm.SearchDates[0], //开始时间
        EndDate: that.searchForm.SearchDates[1], //开始时间
        PageNum: that.paginations.page, //页码
      };
      that.downloadExcelLoading = true;
      API.entityConversionRepurchaseRateStatement_excel(params)
        .then((res) => {
          this.$message.success({
            message: "正在导出",
            duration: "4000",
          });
          const link = document.createElement("a");
          let blob = new Blob([res], { type: "application/octet-stream" });
          link.style.display = "none";
          link.href = URL.createObjectURL(blob);
          link.download = "门店转化率复购率表.xlsx"; //下载的文件名
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .finally(function() {
          that.downloadExcelLoading = false;
        });
    },
  },
  /** 创建实例之前执行函数   */
  beforeCreate() {},
  /**  实例创建完成之后  */
  created() {},
  /**  在挂载开始之前被调用  */
  beforeMount() {},
  /**  实例被挂载后调用  */
  mounted() {
    let that = this;
    that.isExport = permission.permission(that.$route.meta.Permission, "Report-Entity-ConversionRepurchaseRate-Export");
    that.reportEntity_storeList();
    that.entityConversionRepurchaseRateStatement_list();
  },
  /**  数据更新 之前 调用  */
  beforeUpdate() {},
  /** 数据更新 完成 调用   */
  updated() {},
  /**  实例销毁之前调用  */
  beforeDestroy() {},
  /**  实例销毁后调用  */
  destroyed() {},
};
</script>

<style lang="scss">
.ConversionRepurchaseRate {
}
</style>
